import { PageProps } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { RefObject } from 'react'
import { Gallery, Item } from 'react-photoswipe-gallery'

import 'photoswipe/dist/photoswipe.css'

import { SectionContent, TabLink, Tabs } from '../../common/styles'
import SvgFullscreen from '../../icons/FullScreen'
import Layout from '../../layouts'

import {
  Container,
  FullScreenIconContainer,
  ItemContainer,
  Thumbnail,
  ThumbnailContainer,
  Title
} from './PortfolioSection.styles'

const PortfolioSection = ({
  data: {
    allStrapiPortfolio: { edges: allStrapiPortfolio },
    strapiPortfolio: currentPortfolio
  }
}: Pick<PageProps<Queries.PortfolioQuery>, 'data'>) => (
  <Layout
    dark
    smallNavBar
    showCTA
    seo={{
      title: currentPortfolio?.seo?.title,
      description: currentPortfolio?.seo?.description
    }}
  >
    <Container>
      <Tabs>
        {[...allStrapiPortfolio]
          .sort((a, b) => Number(a.node.index) - Number(b.node.index))
          .map(({ node: { id, path, title } }) => (
            <TabLink key={id} to={`/portfolio/${path}/`}>
              {title}
            </TabLink>
          ))}
        <TabLink to='/portfolio/video/'>Vidéos</TabLink>
      </Tabs>
      <Title>Portfolio: {currentPortfolio?.title}</Title>
      <SectionContent>
        <Gallery withCaption>
          <ItemContainer>
            {currentPortfolio?.photos?.map(photo => {
              const fullSizeImage = getImage(
                photo?.localFile?.childImageSharp?.full!
              )
              const thumbnailImage = getImage(
                photo?.localFile?.childImageSharp?.thumbnail!
              )

              if (!fullSizeImage || !thumbnailImage) {
                return null
              }

              return (
                <Item
                  key={photo?.id}
                  original={fullSizeImage.images?.fallback?.src}
                  thumbnail={thumbnailImage.images?.fallback?.src}
                  width={fullSizeImage.width}
                  height={fullSizeImage.height}
                  caption={photo?.alternativeText || undefined}
                >
                  {({ ref, open }) => (
                    <ThumbnailContainer
                      ref={ref as RefObject<HTMLDivElement> & typeof ref}
                      onClick={open}
                    >
                      <Thumbnail
                        image={thumbnailImage}
                        alt={photo?.alternativeText || ''}
                        width={thumbnailImage.width * (2 / 3)}
                        height={200}
                      />
                      {/* Background dark gray with low opacity */}
                      <FullScreenIconContainer>
                        <SvgFullscreen />
                      </FullScreenIconContainer>
                    </ThumbnailContainer>
                  )}
                </Item>
              )
            })}
          </ItemContainer>
        </Gallery>
      </SectionContent>
    </Container>
  </Layout>
)

export default PortfolioSection
