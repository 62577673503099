import * as React from 'react'

const SvgFullscreen = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 384.97 384.97'
    xmlSpace='preserve'
    width='1em'
    height='1em'
    fill='black'
    {...props}
  >
    <path d='M384.97 12.03c0-6.713-5.317-12.03-12.03-12.03H264.847c-6.833 0-11.922 5.39-11.934 12.223 0 6.821 5.101 11.838 11.934 11.838h96.062l-.193 96.519c0 6.833 5.197 12.03 12.03 12.03 6.833-.012 12.03-5.197 12.03-12.03l.193-108.369c0-.036-.012-.06-.012-.084.001-.037.013-.061.013-.097zM120.496 0H12.403c-.036 0-.06.012-.096.012-.024 0-.06-.012-.084-.012C5.51 0 .192 5.317.192 12.03L0 120.399c0 6.833 5.39 11.934 12.223 11.934 6.821 0 11.838-5.101 11.838-11.934l.192-96.339h96.242c6.833 0 12.03-5.197 12.03-12.03C132.514 5.197 127.317 0 120.496 0zM120.123 360.909H24.061v-96.242c0-6.833-5.197-12.03-12.03-12.03S0 257.833 0 264.667v108.092c0 .036.012.06.012.084 0 .036-.012.06-.012.096 0 6.713 5.317 12.03 12.03 12.03h108.092c6.833 0 11.922-5.39 11.934-12.223.001-6.82-5.1-11.837-11.933-11.837zM372.747 252.913c-6.833 0-11.85 5.101-11.838 11.934v96.062h-96.242c-6.833 0-12.03 5.197-12.03 12.03s5.197 12.03 12.03 12.03h108.092c.036 0 .06-.012.084-.012.036-.012.06.012.096.012 6.713 0 12.03-5.317 12.03-12.03V264.847c.001-6.833-5.389-11.934-12.222-11.934z' />
  </svg>
)

export default SvgFullscreen
