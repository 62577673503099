import { graphql, PageProps } from 'gatsby'

import PortfolioSection from '../sections/PortfolioSection'

const Portfolio = ({ data }: PageProps<Queries.PortfolioQuery>) => (
  <PortfolioSection data={data} />
)

export default Portfolio

export const query = graphql`
  query Portfolio($id: String) {
    strapiPortfolio(id: { eq: $id }) {
      id
      title
      photos {
        localFile {
          childImageSharp {
            full: gatsbyImageData(width: 800, formats: [AUTO, WEBP])
            thumbnail: gatsbyImageData(height: 300, formats: [AUTO, WEBP])
          }
        }
        id
        alternativeText
      }
      seo {
        description
        title
      }
    }
    allStrapiPortfolio {
      edges {
        node {
          id
          title
          path
          index
          strapi_id
        }
      }
    }
  }
`
