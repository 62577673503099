import { GatsbyImage } from 'gatsby-plugin-image'
import { ItemRef } from 'react-photoswipe-gallery'
import styled from 'styled-components'

import { TitleLarge } from '../../common/styles'
import breakpoint from '../../constants/breakpoint'
import shadow from '../../constants/shadow'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ThumbnailContainer = styled.div<{ ref: ItemRef }>`
  flex: 1;
  display: flex;
  position: relative;
  max-width: 100%;

  @media ${breakpoint.TABLET} {
    max-width: 400px;
  }
`

export const Thumbnail = styled(GatsbyImage)<{ width: number; height: number }>`
  transform: scale(1);
  cursor: pointer;
  margin: 7px;
  flex: 1;
  object-fit: cover;
  object-position: center center;
  box-shadow: ${shadow.LIGHT_BOX_SHADOW};
  transition: scale, 0.1s ease-in-out;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;

  &:hover {
    transform: scale(1.05);
  }
`

export const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const FullScreenIconContainer = styled.button.attrs({
  'aria-label': "Ouvrir l'image en plein écran"
})`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 21px;
  right: 21px;
  padding: 7px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  pointer-events: none;
`

export const Title = styled(TitleLarge)`
  margin-top: 28px;
  padding-bottom: 0;
  padding-right: 7px;
  padding-left: 7px;
  color: white;
`
